import TitleBar from '../../components/TitleBar'
import React, {useContext, useEffect, useRef, useState} from 'react'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import Form from '../../components/Form'
import SignatureInput from '../../components/SignatureInput'
import {titleList} from '../../data/titleList'
import usePost from '../../hooks/usePost'
import LoadingOverlay from '../../components/LoadingOverlay'
import ReturnMessage from '../../components/ReturnMessage'
import ContractStatus from '../../components/ContractStatus'
import {Link, useNavigate, useParams} from 'react-router-dom'
import ScrollOnMount from '../../components/ScrollOnMount'
import Contract from '../../components/Contract'
import useGet from '../../hooks/useGet'
import ErrorModal from '../../components/ErrorModal'
import {stringsMatch, cleanString} from '../../utilities/string'
import authContext from '../../store/AuthContext'
import exclamationIcon from '../../assets/icons/exclamation-large.svg'
import parse from 'html-react-parser'
import CarrierTerms from '../../components/CarrierTerms'
import SEO from '../../components/SEO'
import poweredByContext from '../../store/PoweredByContext'
import {isManchesterUnited} from '../../utilities/poweredBy'
import envVariables from '../../data/envVariables'

import manchesterUnitedTerms from '../../data/manchesterUnitedTerms'

const SignContract = ({step}) => {

    // State
    const [validateForm, setValidateForm] = useState(false)
    const [signature, setSignature] = useState(titleList[0]['value'])
    const [shyTC, setShyTC] = useState('')
    const [carrierTC, setCarrierTC] = useState('')
    const [tenantTC, setTenantTC] = useState('')
    const [error, setError] = useState(false)

    // Hooks
    const {sending, returnData: successData, returnMessage, postData} = usePost()
    const navigate = useNavigate()
    const legId = useParams().legId
    const tailNum = useParams().tailNum
    const isReportView = useParams().view === 'report'

    const authCtx = useContext(authContext)
    const poweredByCtx = useContext(poweredByContext)

    // Get contract
    const {loading, returnData, error: contractError} = useGet(`/api/frontend/contract-details?lo=${legId}`)
    const downloadUrl = `${envVariables.documentBase}/api/invoice/sign-contract?legId=${legId}&tailNum=${tailNum}`

    // Add PDF view when looking as report
    useEffect(() => {
        if (isReportView) {
            document.body.classList.add('pdf-view')
        }

        // Clean up
        return(() => {
            document.body.classList.remove('pdf-view')
        })
    })

    // Submit
    const submitHandler = (e) => {

        e.preventDefault()
        setValidateForm(true)

        // Form complete
        if (signature && shyTC && carrierTC && (tenantTC || !isManchesterUnited(poweredByCtx)) && (stringsMatch(signature, returnData.cClientName))) {
            postData(cleanString(signature), '/api/frontend/contract-sign?lo=' + legId, 1000)
        }
    }

    // Contract signed, redirect
    useEffect(() => {
        if (returnMessage.success) {
            navigate('/contract-signed', {
                state: {
                    quoteRef: successData.quoteRef,
                    signedDateTime: successData.signedDateTime,
                    totalToPay: successData.totalToPay,
                    currency: successData.currency,
                    legId: legId,
                    tailNum: tailNum
                }
            })
        }
    })

    useEffect(() => {
        if (returnData.expired) {
            setError('The page you have clicked to view has now expired or you have already completed this step.')
        } else if (contractError) {
            setError('Sorry, we were unable to find this contract. Please contact your SHY broker.')
        }
    }, [contractError, returnData])

    const buttonRef = useRef()

    return (
        <>
            {!isReportView &&
                <>
                    {(step === 'read-contract') &&
                        <>
                            <SEO title='Sign Contract' />
                            <TitleBar title='Sign Contract' cta='Quotes' url={`/quotes-and-bookings/quotes`} />
                            <ReturnMessage returnMessage={returnMessage}/>
                        </>
                    }

                    {(step === 'sign-contract') &&
                        <>
                            <SEO title='Sign Contract' />
                            <TitleBar title='Sign Contract' cta='View Contract' url={`/sign-contract/${legId}/${tailNum}`} />
                            <ReturnMessage returnMessage={returnMessage}/>
                        </>
                    }

                    {(step === 'carrier-terms') &&
                        <>
                            <SEO title='Carrier Terms' />
                            <TitleBar title='Carrier Terms' cta='Sign Contract' url={`/sign-contract/add-signature/${legId}/${tailNum}`} />
                            <ReturnMessage returnMessage={returnMessage}/>
                        </>
                    }

                    {(step === 'tenant-terms' && poweredByCtx.isPoweredBy && isManchesterUnited(poweredByCtx)) &&
                        <>
                            <SEO title={`${poweredByCtx?.tenant?.tenantName} General Terms and Conditions`} />
                            <TitleBar title={`${poweredByCtx?.tenant?.tenantName} General Terms and Conditions`} cta='Sign Contract' url={`/sign-contract/add-signature/${legId}/${tailNum}`} />
                            <ReturnMessage returnMessage={returnMessage}/>
                        </>
                    }
                </>
            }

            {(!loading && !error && !isReportView) && <ContractStatus buttonRef={buttonRef}/>}

            <div className='page'>
                {loading ? <LoadingOverlay/> :
                    error ? <ErrorModal errorMessage={error}/>
                        :
                        <>
                            <div className='wrap'>

                                {(step === 'read-contract') &&
                                    <>
                                        <div className='contract-download'>
                                            {!isReportView &&
                                                <Button classNames={['button--secondary']} icon='download' onClick={() => {
                                                    window.open(downloadUrl, '_blank', 'noreferrer')
                                                }}>Download</Button>
                                            }
                                        </div>
                                        <Contract
                                            mode='sign'
                                            isReportView={isReportView}
                                            contractData={returnData}
                                            hideTerms={!isReportView}
                                        />

                                        {(isReportView && returnData.carrierTerms) &&
                                            <CarrierTerms>{parse(returnData.carrierTerms)}</CarrierTerms>
                                        }

                                        {(isReportView && poweredByCtx.isPoweredBy && isManchesterUnited(poweredByCtx)) &&
                                            <CarrierTerms>{parse(manchesterUnitedTerms)}</CarrierTerms>
                                        }

                                        <div className='form-button-wrapper'>
                                            <Link to={`/sign-contract/add-signature/${legId}/${tailNum}`}>
                                                <Button classNames={['button--full']} ref={buttonRef}>Sign Contract</Button>
                                            </Link>
                                        </div>
                                    </>
                                }

                                {(step === 'sign-contract') &&
                                    <ScrollOnMount>
                                        <section className='wrap sign-contract sign-contract--max500'>

                                            <Form classNames={['form--full']} onSubmit={submitHandler}>
                                                <SignatureInput
                                                    label='Write your full name above to sign.'
                                                    required
                                                    requiredName={returnData.cClientName}
                                                    validateForm={validateForm}
                                                    disabled={sending}
                                                    value={(value) => setSignature(value)}
                                                />

                                                <Checkbox
                                                    required
                                                    classNames={['checkbox--small-margin']}
                                                    validateForm={validateForm}
                                                    disabled={sending}
                                                    id='shy-tc'
                                                    value={(value) => setShyTC(value)}
                                                >
                                                    I agree to this contract and its <Link target='_blank' to='/terms-conditions'>Terms and Conditions</Link>.
                                                </Checkbox>

                                                {(poweredByCtx.isPoweredBy && isManchesterUnited(poweredByCtx)) &&
                                                    <Checkbox
                                                        required
                                                        classNames={['checkbox--small-margin']}
                                                        validateForm={validateForm}
                                                        disabled={sending}
                                                        id='tenant-tc'
                                                        value={(value) => setTenantTC(value)}
                                                    >
                                                        I agree to this contract and <Link target='_blank' to={`/sign-contract/tenant-terms/${legId}/${tailNum}`}>Manchester United's General Terms and Conditions.</Link>.
                                                    </Checkbox>
                                                }

                                                {returnData.carrierTerms &&
                                                    <>
                                                        <Checkbox
                                                            required
                                                            classNames={['checkbox--small-margin']}
                                                            validateForm={validateForm}
                                                            disabled={sending}
                                                            id='carrier-tc'
                                                            value={(value) => setCarrierTC(value)}
                                                        >
                                                            I agree to the <Link target='_blank' to={`/sign-contract/carrier-terms/${legId}/${tailNum}`}>Terms & Conditions of Carriage / Operator</Link>.
                                                        </Checkbox>
                                                        <br/>
                                                    </>
                                                }

                                                <div className='form-button-wrapper'>
                                                    <Button classNames={['button--full']}>Confirm</Button>
                                                </div>
                                            </Form>

                                            {sending && <LoadingOverlay/>}

                                        </section>
                                    </ScrollOnMount>
                                }

                                {(step === 'carrier-terms' && returnData.carrierTerms) &&
                                    <CarrierTerms>{parse(returnData.carrierTerms)}</CarrierTerms>
                                }

                                {(step === 'tenant-terms' && poweredByCtx.isPoweredBy && isManchesterUnited(poweredByCtx)) &&
                                    <CarrierTerms>{parse(manchesterUnitedTerms)}</CarrierTerms>
                                }
                            </div>
                        </>
                }
            </div>
        </>
    )
}

export default SignContract